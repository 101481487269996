import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/theme.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap'
import {Content} from "@/app-modules/cms/Content";

library.add(fas, faInstagram, faFacebookF, faLinkedinIn, faTwitter)

const app = createApp(App)
    .use(store)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
const rootContainer = '#app'

export const cmsContent = new Content();
cmsContent.getContentFromCms().then(() => {
    cmsContent.getLinksFromCms().then(() => {
        // Everything is ready, we'll mount the app using the content from strapi
        app.mount(rootContainer)
        // If you need to update the content, you can do it like this:
        // console.log(cmsContent)
        // Copy the elements from the console and replace them into links.ts and en.ts following their existing structure
    }).catch((e) => {
        // Something went wrong with strapi, we'll use the default content
        app.mount(rootContainer)
    })
}).catch((e) => {
    // Something went wrong with strapi, we'll use the default content
    app.mount(rootContainer)
})